import { NFT_AUCTIONS } from '../../api/constants'
import { getEntity, getListEntitys } from '../../api/endpoints'
import { getAuctionStatus, getClaimButtonStatus } from './helpers'
import {
    getNftAuctionById,
    nftAuctionBID,
    nftAuctionWithdraw,
    nftGetAuctionBalance,
    nftGetAuctionHighestBid,
    getNftTransactionHash,
} from '../../web3/web3.api'
import { getAuctionContractById, getAuctionListStrapi, getAuctionStrapiById } from './actions'
import { convertETH } from '../../helpers'
import { toast } from 'react-toastify'
import { toastMessages } from '../../components/UI/toastMessages'
import { AUCTIONS_PER_PAGE } from '../../constants/constants'
import { Dispatch } from 'redux'
import { AuctionStrapiTypes } from '../../components/Auction/typings'

export const handleGetAuctionStrapiDataById = (auctionId: number) => async (dispatch: Dispatch<any>) => {
    try {
        const resp = await getEntity(NFT_AUCTIONS, auctionId)
        const status: string = getAuctionStatus(resp)
        dispatch(getAuctionStrapiById({ data: resp, status }))
    } catch (e) {}
}

export const handleGetAuctionContractDataById =
    (auctionId: number) => async (dispatch: Dispatch<any>, getState: () => any) => {
        try {
            const { context } = getState().web3Context
            const { strapiData } = getState().auctionState

            const nftAuction = await getNftAuctionById(auctionId, context)
            const status = getAuctionStatus(strapiData.data, nftAuction)
            const hash = await getNftTransactionHash(nftAuction.highestBid, nftAuction.highestBidder, context)
            const withdrawAmount = await nftGetAuctionBalance(auctionId, context.account, context)
            const claimButtonStatus = await getClaimButtonStatus({
                auctionStatus: status,
                contractData: nftAuction,
                auctionData: strapiData.data,
                context: context,
            })

            dispatch(getAuctionContractById({ data: nftAuction, status, hash, withdrawAmount, claimButtonStatus }))
        } catch (e) {}
    }

export const handleBuyAuction = () => async (dispatch: Dispatch<any>, getState: () => any) => {
    const { strapiData, contractData, withdrawAmount } = getState().auctionState
    const { context } = getState().web3Context
    try {
        const resp = await nftAuctionBID(
            {
                auctionId: strapiData.data.id,
                amount: convertETH(contractData.data.buyNowPriceWei) - withdrawAmount,
                account: context.account,
            },
            context
        )
        if (resp.status) {
            toast.success(toastMessages.betConfirmToast({ text: 'Buy confirmed!', hash: resp.transactionHash }), {
                autoClose: false,
                draggable: false,
                closeOnClick: false,
            })
        } else toast.error(resp.message)
    } catch (e) {
    } finally {
        dispatch(handleGetAuctionContractDataById(strapiData.data.id))
    }
}

export const handleBet = (currentBet: number) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const { strapiData, withdrawAmount } = getState().auctionState
    const { context } = getState().web3Context
    try {
        const highestBid = await nftGetAuctionHighestBid(strapiData.data.id, context)
        if (highestBid >= currentBet) {
            toast.warning('Your bet is not the highest!')
        } else {
            const resp = await nftAuctionBID(
                {
                    auctionId: strapiData.data.id,
                    amount: currentBet - withdrawAmount,
                    account: context.account,
                },
                context
            )
            if (resp.status) {
                toast.success(toastMessages.betConfirmToast({ text: 'Bet confirmed!', hash: resp.transactionHash }), {
                    autoClose: false,
                    draggable: false,
                    closeOnClick: false,
                })
            } else toast.error(resp ? resp.message : 'Incorrected auction')
        }
    } catch (e) {
    } finally {
        dispatch(handleGetAuctionContractDataById(strapiData.data.id))
    }
}

export const withdraw = () => async (dispatch: Dispatch<any>, getState: () => any) => {
    const { strapiData } = getState().auctionState
    const { context } = getState().web3Context
    try {
        const resp = await nftAuctionWithdraw({ auctionId: strapiData.data.id, account: context.account }, context)
        if (resp.status) {
            toast.success('Withdraw confirmed!')
            return 'test'
        } else toast.error(resp.message)
    } catch (e) {
        console.error('withdraw', e.message)
        return 'test'
    } finally {
        dispatch(handleGetAuctionContractDataById(strapiData.data.id))
    }
}

let prevPage: number | null = null

export const handleGetAuctionListFromStrapi =
    (start: number) => async (dispatch: Dispatch<any>, getState: () => any) => {
        try {
            const { auctionList } = getState().auctionState
            const { context } = getState().web3Context

            let auctions = auctionList.data
            let resp

            if (start !== prevPage) {
                prevPage = start
                resp = await getListEntitys(
                    `nftAuctions?isUnlisted=false&_sort=auctionPriority:desc,id:asc&_limit=${AUCTIONS_PER_PAGE}&_start=${start}`
                )

                if (resp) {
                    auctions = resp.auctions.map((item: AuctionStrapiTypes) => {
                        const status = getAuctionStatus(item)
                        return {
                            ...item,
                            status,
                        }
                    })
                }
            }

            if (auctions && context.active) {
                auctions = await Promise.all(
                    auctions.map(async (auction: AuctionStrapiTypes) => {
                        const nftAuction = await getNftAuctionById(auction.id, context)
                        const status = getAuctionStatus(auction, nftAuction)

                        return {
                            ...auction,
                            status,
                            contractData: nftAuction,
                        }
                    })
                )
            }

            dispatch(getAuctionListStrapi({ auctions, total: resp ? resp.count : false }))
        } catch (e) {
            console.error('ERROR', e.message)
        }
    }
