import React from 'react'
import { useAuctionTimer } from '../../hooks/auctionTimer'
import { statuses } from '../../store/auction/constants'
import styles from './styles.module.scss'
import { AuctionTimerProps } from './typings'

export const AuctionTimer = ({ status, timeLeft, isWhite, reserve, auctionId }: AuctionTimerProps) => {
    const time = useAuctionTimer(status, timeLeft, auctionId)

    return (
        <div className={`${styles.timer} ${isWhite ? styles.timerWhite : ''}`}>
            <p className={styles.timer__label}>
                {statuses.pending === status
                    ? 'Auction starts in'
                    : statuses.active === status
                    ? 'Auction Open'
                    : 'Time Remaining'}
            </p>

            {statuses.pending === status || (statuses.active === status && !reserve) ? (
                <>
                    <div className={styles.timer__wrapper}>
                        <p className={styles.timer__text}>
                            <span>{time.days}</span> days
                        </p>
                        <p className={styles.timer__text}>
                            <span>{time.hours}</span> hours
                        </p>
                        <p className={styles.timer__text}>
                            <span>{time.minutes}</span> minutes
                        </p>
                        <p className={styles.timer__text}>
                            <span>{time.seconds}</span> seconds
                        </p>
                    </div>
                </>
            ) : (
                <div className={styles.timer__wrapper}>
                    {reserve && status !== statuses.finished ? (
                        <>
                            <p className={styles.timer__reserve}>BIDDING OPEN NOW</p>
                            <p className={styles.timer__reserve}>
                                (Countdown begins{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="//blog.rare.market/article/auction-rules"
                                >
                                    when reserve is met
                                </a>
                                )
                            </p>
                        </>
                    ) : (
                        <p className={styles.timer__info}>Auction finished!</p>
                    )}
                </div>
            )}
        </div>
    )
}
