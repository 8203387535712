import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { timeLeftTypes } from '../helpers'
import { statuses } from '../store/auction/constants'
import { handleGetAuctionContractDataById, handleGetAuctionStrapiDataById } from '../store/auction/handlers'

export const useAuctionTimer = (status: string, timeLeft: timeLeftTypes, auctionId: number) => {
    const [time, setTime] = useState(timeLeft)
    const dispatch = useDispatch()

    useEffect(() => {
        setTime(timeLeft)
    }, [timeLeft])

    useEffect(() => {
        let timeout: number | null = null
        if (timeout) {
            clearTimeout(timeout)
        }

        const updateTimer = () => {
            timeout = window.setTimeout(() => {
                let { days, hours, minutes, seconds } = time
                seconds--
                if (seconds < 0) {
                    minutes--
                    seconds = 59
                }
                if (minutes < 0) {
                    hours--
                    minutes = 59
                }
                if (hours < 0) {
                    days--
                    hours = 23
                }
                if (days === -1) {
                    dispatch(handleGetAuctionStrapiDataById(auctionId))
                    dispatch(handleGetAuctionContractDataById(auctionId))
                }
                setTime({ days, hours, minutes, seconds })
            }, 1000)
        }
        if (statuses.pending === status || statuses.active === status) updateTimer()

        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [status, time])

    return time
}
