import React from 'react'
import config from '../../../config/config'
import styles from './styles.module.scss'

export const toastMessages = {
    betConfirmToast: ({ text, hash }: { text: string; hash: string }) => (
        <div className={styles.toastContent}>
            <p className={styles.toastContent__text}>{text}</p>
            <p className={styles.toastContent__text}>
                Hash : <b>{hash}</b>
            </p>
            <a
                className={styles.toastContent__link}
                target="_blank"
                rel="noopener noreferrer"
                href={`${config.etherscanTxUrl}/${hash}`}
            >
                View on etherscan
            </a>
        </div>
    ),
}
