interface imageTypes {
    bannerImg?: { url: string }
    artist: { backgroundImg?: { url: string } }
}

export const getMaiAuctionImage = (data: imageTypes) =>
    data.bannerImg && data.bannerImg.url
        ? data.bannerImg.url
        : data.artist.backgroundImg && data.artist.backgroundImg.url


export const getReserveStatus = (reserve?: number | null, lastBid?: number | null) => {
    if (!reserve) {
        return false
    }

    if (lastBid) {
        return lastBid < reserve
    }

    return true
}