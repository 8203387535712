import { isAuctionTokenClaimed } from '../../api/endpoints'
import { getAuctionTimeLeft, getCloudinaryImg } from '../../helpers'
import { getBalanceOfAuction } from '../../web3/web3.api'
import { statuses } from './constants'
import DefaultCardImg from '../../assets/images/eric_thomas.jpg'
import { getClaimButtonStatusTypes } from './typings'
import { AuctionContractTypes, AuctionStrapiTypes } from '../../components/Auction/typings'
import { AuctionCardTypes } from '../../components/AuctionCard/typing'

const convertStrapiDate = (date: string) => new Date(date)
const convretUnixDate = (time: string) => new Date(parseInt(time) * 1000)

export const calcAuctionStatus = ({
    sAuctionStatus = '',
    sStartTimestamp = '',
    sDurationDays = 0,
    // cAuctionStart = 0,
    cAuctionEnd = '0',
    cEnded = false,
}) => {
    const currentDate = new Date()
    if (cAuctionEnd && cAuctionEnd !== '0') {
        if (cEnded) return statuses.finished
        else if (currentDate < convertStrapiDate(sStartTimestamp)) return statuses.pending
        else if (currentDate > convertStrapiDate(sStartTimestamp) && currentDate < convretUnixDate(cAuctionEnd))
            return statuses.active
        else if (currentDate > convretUnixDate(cAuctionEnd)) return statuses.finished
        else throw Error('Incorrect value in cAuctionStart or cAuctionEnd')
    } else {
        if (sAuctionStatus.toLowerCase() === statuses.finished.toLowerCase()) return statuses.finished
        else if (currentDate < convertStrapiDate(sStartTimestamp)) return statuses.pending
        else if (
            currentDate > convertStrapiDate(sStartTimestamp) &&
            currentDate < strapiEndDate(sStartTimestamp, sDurationDays)
        )
            return statuses.active
        else if (currentDate > strapiEndDate(sStartTimestamp, sDurationDays)) return statuses.finished
        else throw Error('Incorrect value in sAuctionStatus or sStartTimestamp or sDurationDays')
    }
}

export const strapiEndDate = (start: string, duration: number) => {
    const date: Date = new Date(start)
    date.setDate(date.getDate() + duration)
    return date
}

export const getAuctionStatus = (
    strapiData: AuctionStrapiTypes | AuctionCardTypes,
    contractData?: AuctionContractTypes
) => {
    let status = statuses.finished
    const { auctionStatus, startTimestamp, durationDays } = strapiData

    try {
        if (contractData) {
            const { auctionEnd, auctionEnded } = contractData

            status = calcAuctionStatus({
                sAuctionStatus: auctionStatus,
                sStartTimestamp: startTimestamp,
                sDurationDays: durationDays,
                cAuctionEnd: auctionEnd,
                cEnded: auctionEnded,
            })

            return status
        }

        status = calcAuctionStatus({
            sAuctionStatus: auctionStatus,
            sStartTimestamp: startTimestamp,
            sDurationDays: durationDays,
        })
    } catch (error) {
        console.error(error.message)
    }

    return status
}

export const getClaimButtonStatus = async ({
    auctionStatus,
    contractData,
    auctionData,
    context,
}: getClaimButtonStatusTypes) => {
    let isClaimVisible = false
    let isUserClaimed = false
    const account = context.account

    if (statuses.finished === auctionStatus && contractData.highestBidder === account) {
        try {
            const result = account && (await getBalanceOfAuction(account, auctionData.tokenId, context))
            const isAuctionClaimed =
                account && (await isAuctionTokenClaimed({ claimedBy: account, auctionId: auctionData.id }))
            const isButtonVisible = Number(result.balance) <= 1
            if (isButtonVisible && isAuctionClaimed && !isAuctionClaimed.error && isAuctionClaimed.data.isAuthorized) {
                isClaimVisible = true
                if (isAuctionClaimed.data.claimed) {
                    isUserClaimed = true
                }
            }
        } catch (e) {
            console.error({ error: true, message: e.message })
        }
    }
    return { isClaimVisible, isUserClaimed }
}

export const getAuctionImage = (data: {
    profileImg?: { url: string; alternativeText: string }
    cardImg?: { url: string; alternativeText: string }
}) => {
    const src = data.profileImg
        ? getCloudinaryImg(data.profileImg.url, 1000, 1000)
        : data.cardImg
        ? getCloudinaryImg(data.cardImg.url, 1000, 1000)
        : DefaultCardImg
    const alt = data.profileImg ? data.profileImg.alternativeText : data.cardImg ? data.cardImg.alternativeText : 'N/A'
    return { src, alt }
}

export const calcTimeLeft = (
    contractData: AuctionContractTypes | false | null,
    strapiData: AuctionStrapiTypes | AuctionCardTypes,
    status: string
) => {
    let time: {
        days: number
        hours: number
        minutes: number
        seconds: number
    }
    if (!contractData || contractData.auctionStart === '0')
        time = getAuctionTimeLeft(
            strapiEndDate(strapiData.startTimestamp, status === statuses.pending ? 0 : strapiData.durationDays)
        )
    else
        time = getAuctionTimeLeft(
            new Date(status === statuses.pending ? +contractData.auctionStart * 1000 : +contractData.auctionEnd * 1000)
        )

    return time
}
